


import Faq from "../../landing/landing-free/faq";
export default {
  name: 'ButtonSection',
  components: {Faq},
  data() {
    return {

    }
  },

  computed: {
    selectedPlan() {
      return this.$store.state.selectedPlan
    },
    maxStep() {

      if (this.selectedPlan === 0 || this.selectedPlan === 1 || this.selectedPlan === 3 || this.selectedPlan === 4) {
        return 3
      }
      return 5

    },

    planToRelink() {
      let x;
        switch (this.$store.state.selectedPlan) {
           case 1: x = 1
        }
    return x;
    },

    queryPlan() {
      switch(+this.$store.state.selectedPlan) {
        case 0:
        case 1:
          return '1';

        case 2:
          return '8';

        case 3:
        case 4:
          return '17';

        case 5:
          return '2'
      }
    },

  },

  methods: {
    mainSubmit() {
      if (this.$store.state.userModule.isLogged) {
        this.$store.commit('relocateFromSteps')
      } else
      if (this.$store.state.selectedPlan > 0) {
        this.$router.push(`/auth/register?plan=${this.queryPlan}`)
      } else {
        this.$router.push(`/auth/register/`)
      }
    }
  },

  mounted () {
  }
}
